.App {
    text-align: center;
    overflow-x: hidden;
    overflow-y: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #161e1e;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.titlu {
    margin-top: 1rem;
    color: #47abc6;
    font-size: calc(40px + 2vmin);
}

.scrollable-div {
    margin-top: 3rem;
    margin-bottom: 3rem;
    height: 31rem;
    width: 47rem;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    padding: 20px;
    background-color: #2152d7;
    color: rgb(255, 255, 255);
}

.scrollable-div::-webkit-scrollbar {
    width: 6px;
}

.scrollable-div::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
    border-radius: 6px;
}

.scrollable-div::-webkit-scrollbar-track {
    background-color: #2f2525;
}

.scrollable-div::-webkit-scrollbar-thumb:horizontal {
    background-color: #4a4a4a;
    border-radius: 6px;
}

.scrollable-div::-webkit-scrollbar-track:horizontal {
    background-color: #2f2525;
}

.scrollable-div::-webkit-scrollbar-thumb:vertical {
    height: 2rem;
}

.scrollable-div::-webkit-scrollbar-button {
    display: none;
}

h1 {
    font-size: 100%;
}

.titlu-div {
    margin-bottom: 2rem;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

